import { Injectable } from "@angular/core";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { environment } from "environments/environment";
import { KgSecurityElidModel } from "src/app/model/kg-security/kg-security-elid.model";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { KgElidConfigResponse } from "src/app/model/kg-communities-config/ElidConfig/kg-elid-config-response";
import { KgReviver } from "src/app/framework/model/kg-reviver";

@Injectable()
export class KgSecurityElidService {
  random = Math.random();
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  sendNewVisitor(newVisitor: KgSecurityElidModel) {
    return this.httpClient.post<string>(
      `${environment.ElidApiHost}api/evis/addvisitor`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        params: {
          // path: newVisitor.path,
          username: newVisitor.username,
          password: newVisitor.password,
          visitoruniqeid: newVisitor.visitorid,
          name: newVisitor.name,
          level: newVisitor.level,
          starttime: newVisitor.starttime,
          endtime: newVisitor.endtime,
          address1: newVisitor.address1,
          address2: newVisitor.address2,
          address3: newVisitor.address3,
          unitnumber: newVisitor.unitnumber,
          who: newVisitor.who,
          purpose: newVisitor.purpose
        }
      }
    );
  }

  getCommunitiesElidConfig(): Observable<KgElidConfigResponse> {
    return this.httpClient
      .get<KgElidConfigResponse>(
        `${environment.ApiHost}api/communitiesconfig/elidconfig`
      )
      .pipe(
        map(x =>
          KgReviver.Revive<KgElidConfigResponse>(x, KgElidConfigResponse)
        ),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
