import { IdentificationTypes } from "../../model/kg-core/kg-core.model";
import { KgBaseModel } from "../../model/kg-core/kg-base.model";
import { KgFileItemInfoModel } from "src/app/model/kg-filemanager/kg-filemanager.model";
import { KgUnitModel } from "src/app/model/kg-units/kg-unit.model";

export class KgSecurityElidModel extends KgBaseModel {
  // path: string;
  username: string;
  password: string;
  visitorid: string;
  name: string;
  level: string;
  starttime: string;
  endtime: string;
  address1: string;
  address2: string;
  address3: string;
  unitnumber: string;
  who: string;
  purpose: string;

  constructor(securityElid?: KgSecurityElidModel) {
    super();
    Object.assign(this, {
      // path: "",
      username: "",
      password: "",
      mykad: "",
      name: "",
      level: "",
      starttime: "",
      endtime: "",
      address1: "",
      address2: "",
      address3: "",
      unitnumber: "",
      tenant: "",
      purpose: ""
    });
  }
}
