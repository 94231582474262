import { KgBaseModel } from "../../kg-core/kg-base.model";
import { ElidConfigModel } from "./kg-elid-config.model";

export class KgElidConfigResponse extends KgBaseModel {
  ElidConfig: ElidConfigModel;
  constructor(elidConfigResponse?: KgElidConfigResponse) {
    super();
    if (elidConfigResponse != null) {
      Object.assign(this, elidConfigResponse);
    }
  }
}
